import Airtable from "airtable";

// Configure Airtable
Airtable.configure({
  endpointUrl: "https://api.airtable.com",
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY
});

const base = Airtable.base(process.env.REACT_APP_AIRTABLE_BASE_ID);
export default base;

export const users_table = base("users");
export const students_table = base("students");
export const projects_table = base("projects");
export const schools_table = base("schools");
export const awards_table = base("awards");
