import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

// Import assets
import error404_img from "assets/img/error404.svg";

const NotFoundPage = (props) => {
  const navigate = useNavigate();

  // automatic redirect to homepage after 5 seconds
  const [countdown, setCountdown] = useState(5);
  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    const redirectTimer = setTimeout(() => {
      navigate("/");
    }, 5000);

    return () => {
      clearTimeout(timer);
      clearInterval(redirectTimer);
    };
  }, [navigate]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        padding: 4,
        boxSizing: "border-box"
      }}
    >
      <img
        src={error404_img}
        alt="404 Error"
        style={{ width: "33%", objectFit: "contain" }}
      />
      <Box
        margin={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Typography variant="body1" fontSize="1.25rem" textAlign="center">
          We're sorry, the page you requested could not be found.
        </Typography>
        <Typography variant="body1" fontSize="1.25rem" textAlign="center">
          You will be taken back to the homepage in {countdown} seconds.
        </Typography>
      </Box>
      <Button variant="contained" onClick={() => navigate("/")}>
        Return to Homepage
      </Button>
    </Box>
  );
};

export default NotFoundPage;
