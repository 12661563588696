import React, { useState } from "react";
import PropTypes from "prop-types";

import { useProject } from "contexts/ProjectContext";
import { useAuth } from "contexts/AuthContext";

import { Box, Typography, IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";

const AwardEntry = ({ projectID, userID, details }) => {
  const { projects, students } = useProject();
  const { users } = useAuth();
  const project = projects.find((project) => project.id === projectID);
  const team_leader = users.find(
    (user) => user.id === project?.fk_registrant_user[0]
  );
  const studentsNames = project?.rollup_student_names || [];
  const projectStudents = project?.fk_students
    ?.map((studentID) => students.find((student) => student.id === studentID))
    .filter((student) => student !== undefined);
  const award_user = users.find((user) => user.id === userID);

  const [copied, setCopied] = useState("");

  const handleCopy = (text, identifier) => {
    navigator.clipboard.writeText(text);
    setCopied(identifier);

    // Set a timeout to remove the highlight and reset button after 3 seconds
    setTimeout(() => {
      setCopied("");
    }, 3000);
  };

  if (projectID && !details) {
    return (
      <Box mb={1.25} pl={2}>
        <Typography variant="h6">{studentsNames.join(", ")}</Typography>
        <Typography variant="body1" fontStyle="italic">
          {project.project_title}
        </Typography>
      </Box>
    );
  }
  if (projectID && details) {
    return (
      <Box mb={1.25} pl={2}>
        <Typography variant="h6" fontStyle="italic">
          {project.project_title}
        </Typography>

        <Typography variant="body1" fontWeight={600} fontSize={"1.1rem"}>
          Team Leader/Registrant:{" "}
          <span style={{ fontWeight: 400 }}>
            {team_leader.user_full_name} — {team_leader.email_primary}
          </span>
        </Typography>
        <Typography variant="body1" fontWeight={600} fontSize={"1.1rem"}>
          Team Members:
        </Typography>
        {projectStudents.length > 0 &&
          projectStudents.map((student) => (
            <div key={student.id}>
              <div
                style={{
                  display: "flex",
                  gap: "4px"
                }}
              >
                <Typography
                  variant="body1"
                  fontSize={"1.1rem"}
                  sx={{
                    background:
                      copied === `email-${student.id}` ? "#ff0" : "transparent"
                  }}
                >
                  {student.full_name} — {student.emergency_email}{" "}
                </Typography>
                <Tooltip
                  title={
                    copied === `email-${student.id}`
                      ? "Email copied to clipboard"
                      : "Copy email to clipboard"
                  }
                >
                  <IconButton
                    size="small"
                    onClick={() => {
                      handleCopy(
                        student.emergency_email,
                        `email-${student.id}`
                      );
                    }}
                  >
                    {copied === `email-${student.id}` ? (
                      <CheckIcon fontSize="inherit" />
                    ) : (
                      <ContentCopyIcon fontSize="inherit" />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "4px"
                }}
              >
                <Typography
                  variant="body1"
                  fontSize={"1.1rem"}
                  sx={{
                    background:
                      copied === `address-${student.id}`
                        ? "#ff0"
                        : "transparent"
                  }}
                >
                  {student.fx_address}
                </Typography>
                <Tooltip
                  title={
                    copied === `address-${student.id}`
                      ? "Address copied to clipboard"
                      : "Copy address to clipboard"
                  }
                >
                  <IconButton
                    size="small"
                    onClick={() => {
                      handleCopy(student.fx_address, `address-${student.id}`);
                    }}
                  >
                    {copied === `address-${student.id}` ? (
                      <CheckIcon fontSize="inherit" />
                    ) : (
                      <ContentCopyIcon fontSize="inherit" />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          ))}
      </Box>
    );
  }
  if (userID) {
    return (
      <Box mb={1.25} pl={2}>
        <Typography variant="h6">{award_user?.user_full_name}</Typography>
      </Box>
    );
  }
};

AwardEntry.propTypes = {
  projectID: PropTypes.string,
  userID: PropTypes.string,
  details: PropTypes.bool
};

export default AwardEntry;
