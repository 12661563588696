import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

// Import helper functions
import { checkInProject } from "helpers/airtable.helper";

// Import MUI Components
import { Box, Typography, Button, Checkbox, Alert } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import StatusChip from "components/StatusChip";
import ScreenshotIcon from "@mui/icons-material/Screenshot";

const CheckInResultsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const { filteredProjects } = location?.state || [];

  // States
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [viewState, setViewState] = useState("result");

  // Variables
  const hasMissingForms = (project) => {
    return (
      (project?.num_attachments < 1 ||
        !project?.num_attachments ||
        project?.status?.length > 0) &&
      project?.project_division === "Senior Division"
    );
  };
  const anyProjectsWithMissingForms = selectedProjects.some(hasMissingForms);

  const handleSelectProject = (project) => {
    if (selectedProjects.includes(project)) {
      setSelectedProjects(selectedProjects.filter((id) => id !== project));
    } else {
      setSelectedProjects([...selectedProjects, project]);
    }
  };

  //   return to check-in page if no filtered projects
  useEffect(() => {
    if (!filteredProjects || filteredProjects.length === 0) {
      navigate("/check-in");
    }
  }, [filteredProjects, navigate]);

  const handleCheckIn = async () => {
    const projectIDsToCheckIn = selectedProjects.map((project) => project.id);
    try {
      await checkInProject(projectIDsToCheckIn);
      setViewState("checked-in");
    } catch (error) {
      console.error(error);
    }
  };

  const projectDivisionColor = (project) => {
    if (project.project_division === "Junior Division") {
      return "#fff1a0";
    } else if (project.project_division === "Senior Division") {
      return "#b8e7ff";
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#E6E6E9",
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        boxSizing: "border-box"
      }}
    >
      <Box
        sx={{
          width: "100vw",
          height: "90vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          border: "1px solid green",
          gap: 4,
          overflow: "hidden",
          padding: 3,
          boxSizing: "border-box"
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row"
          }}
        >
          <Button
            variant="outlined"
            startIcon={<ArrowBackIosIcon />}
            //   size="small"
            sx={{
              minWidth: "140px"
            }}
            onClick={() => {
              navigate("/check-in");
            }}
          >
            Search again
          </Button>
          <Typography
            variant="h6"
            textAlign="center"
            flexGrow={1}
            sx={{
              minWidth: "50%"
            }}
          >
            {viewState === "result"
              ? "Select the projects you want to check in"
              : "Checked-in projects"}
          </Typography>
          {/* <Box
          sx={{
            minWidth: "200px"
          }}
        ></Box> */}
        </Box>
        <Box
          sx={{
            overflowY: "scroll",
            width: "100%",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            gap: 2
          }}
        >
          {viewState === "result" && (
            <>
              {filteredProjects?.map((project) => {
                return (
                  <Box
                    key={project.id}
                    sx={{
                      background: "white",
                      padding: 2,
                      //   marginBottom: 2,
                      borderRadius: 3,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      border: selectedProjects.includes(project)
                        ? `3px solid ${theme.palette.success.main}`
                        : "none",
                      boxSizing: "border-box"
                    }}
                    onClick={() => handleSelectProject(project)}
                  >
                    <Box mr={2}>
                      <Checkbox
                        checked={selectedProjects.includes(project)}
                        color="success"
                      />
                    </Box>

                    <Box>
                      <Typography variant="h6" fontWeight={500} m={0} p={0}>
                        {project.project_num_title}
                      </Typography>
                      <Typography variant="body1">
                        {project.rollup_student_names}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </>
          )}
          {viewState === "checked-in" && (
            <>
              {selectedProjects?.map((project) => {
                return (
                  <Box
                    key={project.id}
                    sx={{
                      background: "white",
                      //   padding: 2,
                      //   marginBottom: 2,
                      borderRadius: 3,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      backgroundColor: projectDivisionColor(project),
                      minHeight: "120px",
                      boxSizing: "border-box",
                      border: "3px solid rgba(0,0,0,0.5)"
                    }}
                  >
                    <Box
                      sx={{
                        minWidth: "120px",
                        borderRight: "3px solid rgba(0,0,0,0.5)",
                        // mr: 2,
                        height: "100%",
                        // boxSizing: "border-box",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <Box
                        sx={{
                          padding: 1,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          boxSizing: "border-box"
                        }}
                      >
                        <Typography variant="body1">TABLE</Typography>
                        <Typography
                          variant="h5"
                          fontSize={"2.25rem"}
                          fontWeight={500}
                        >
                          {project.table_location}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          padding: 1,
                          borderTop: "3px solid rgba(0,0,0,0.5)",
                          width: "100%",
                          boxSizing: "border-box"
                        }}
                      >
                        <Typography variant="body1" textAlign="center">
                          CHECKED IN
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        p: 2
                      }}
                    >
                      <Typography
                        variant="h6"
                        fontSize="1.1rem"
                        fontWeight={400}
                        m={0}
                        p={0}
                      >
                        #{project.proj_num}
                      </Typography>
                      <Typography variant="h6" fontWeight={500} m={0} p={0}>
                        {project.project_title}
                      </Typography>
                      <Typography variant="body1">
                        {project.rollup_student_names}
                      </Typography>
                      {project.project_division === "Senior Division" && (
                        <Box mt={1}>
                          <StatusChip project={project} />
                        </Box>
                      )}
                    </Box>
                  </Box>
                );
              })}
            </>
          )}
        </Box>
        {viewState === "result" && (
          <Button
            variant="contained"
            fullWidth
            sx={{
              height: 48
            }}
            onClick={handleCheckIn}
          >
            Check in {selectedProjects.length} selected projects
          </Button>
        )}
        {viewState === "checked-in" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              boxSizing: "border-box"
            }}
          >
            <Alert
              severity="success"
              icon={<ScreenshotIcon fontSize="inherit" />}
            >
              Take a screen shot to save your table information
            </Alert>
            {anyProjectsWithMissingForms && (
              <Alert severity="error" variant="filled">
                One or more projects are missing ISEF forms. Please see a CNYSEF
                event coordinator to ensure all forms are submitted.
              </Alert>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CheckInResultsPage;
