import React from "react";
import PropTypes from "prop-types";

// Import MUI components
import Chip from "@mui/material/Chip";

const StatusChip = ({ project }) => {
  if (project?.final_approved || project?.reviewed_by?.length >= 2) {
    return <Chip label="ISEF Approved" color="success" variant="contained" />;
  } else if (project?.num_attachments < 1 || !project.num_attachments) {
    return (
      <Chip
        label="No documents attached/uploaded"
        color="error"
        variant="contained"
      />
    );
  } else if (
    (project?.status?.length === 0 || !project.status) &&
    (project.reviewed_by?.length < 2 || !project.reviewed_by)
  ) {
    return (
      <Chip
        label="Pending review"
        variant="contained"
        sx={{
          backgroundColor: "#d1a700",
          color: "white"
          // borderColor: "#d1a700"
        }}
      />
    );
  } else {
    return project.status?.map((status, index) => (
      <Chip
        key={index}
        label={status}
        color="warning"
        variant="contained"
        sx={{ mr: 1, my: 0.2 }}
      />
    ));
  }
};

StatusChip.propTypes = {
  project: PropTypes.object.isRequired
};

export default StatusChip;
