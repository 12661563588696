import {
  users_table,
  students_table,
  projects_table,
  awards_table
  //   schools_table
} from "config/airtable-config";

export const fetchAllUsers = async () => {
  try {
    const usersResponse = await users_table.select().all();
    if (usersResponse.length > 0) {
      return usersResponse.map((user) => {
        return {
          id: user.id,
          PKID: user.id,
          ...user.fields
        };
      });
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
    throw new Error(error.message);
  }
};

export const fetchAllStudents = async () => {
  try {
    const studentsResponse = await students_table.select().all();
    if (studentsResponse.length > 0) {
      return studentsResponse.map((student) => {
        return {
          id: student.id,
          PKID: student.id,
          ...student.fields
        };
      });
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
    throw new Error(error.message);
  }
};

export const fetchAllProjects = async () => {
  try {
    const projectsResponse = await projects_table
      .select({
        view: "Table Locations"
      })
      .all();
    if (projectsResponse.length > 0) {
      return projectsResponse.map((project) => {
        return {
          id: project.id,
          PKID: project.id,
          ...project.fields
        };
      });
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
    throw new Error(error.message);
  }
};

export const fetchAllAwards = async () => {
  try {
    const awardsResponse = await awards_table
      .select({
        sort: [{ field: "award_order", direction: "asc" }]
      })
      .all();
    if (awardsResponse.length > 0) {
      return awardsResponse.map((award) => {
        return {
          id: award.id,
          PKID: award.id,
          ...award.fields
        };
      });
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const checkInProject = async (projectIDs) => {
  try {
    const updatedRecords = projectIDs.map((id) => {
      return {
        id,
        fields: {
          checked_in: true
        }
      };
    });
    const updatedProjects = await projects_table.update(updatedRecords);
    return updatedProjects;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
