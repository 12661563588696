import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AuthProvider } from "contexts/AuthContext";
import { ProjectProvider } from "contexts/ProjectContext";
import { AwardsProvider } from "contexts/AwardsContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ProjectProvider>
        <AwardsProvider>
          <App />
        </AwardsProvider>
      </ProjectProvider>
    </AuthProvider>
  </React.StrictMode>
);
